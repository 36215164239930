import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertController, LoadingController, ModalController, NavParams } from '@ionic/angular';
import { from, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from '../../../environments/environment';
import { CommonUtils } from 'src/app/services/common-utils/common-utils';
import { CommonHeaderComponent } from 'src/app/components/common-header/common-header.component';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {

  @Output() counterChange = new EventEmitter<number>();
  showAddtoCart_Url: string;
  showAddtoCartLoadData: boolean;
  showWishlist_Url: string;
  showWishlistLoadData: boolean;

  private formSubmitSubscribe: Subscription;
  private registerFormSubscribe: Subscription;
  private otpValidationFormSubscribe: Subscription;
  private createPasswordFormSubscribe: Subscription;
  private opencartDataSubscribe: Subscription;
  private incrementDecrementDataSubscribe: Subscription;
  private addTocartDataSubscribe: Subscription;
  private addCartFromTmpCartDataSubscribe: Subscription;
  private openwishlistDataSubscribe: Subscription;
  private wishlistDataSubscribe: Subscription;
  private changePasswordFormSubscribe: Subscription;
  private canclOrderFormSubscribe: Subscription;
  private returnReplaceOrderFormSubscribe: Subscription;
  private refundFormSubscribe: Subscription;
  

  file_URL = environment.fileUrl;
  main_url = environment.apiUrl;
  
  get_identifier;
  get_item;
  get_array;
  heder_title;
  hide = true;
  hide2 = true;
  hide3 = true;
  isLoading = false;
  api_url;
  loginbox = true;
  otpbox = false;
  sendagain = false;
  passwordbox = false;
  otpvalidation_api_url;
  registerReturnData: any = [];
  registerReturnOTP: any = [];
  password: any;
  createpassword_api_url;
  open_otp: boolean;
  opencartLoadData;
  opencartReturnData;
  opencart_Url;
  opentotaldata: any;
  total: any;
  productArray: any = [];
  loginReturnData: any;
  allProductQuantity: number = 1;
  addCartFromTmpCartLoadData;
  addCartFromTmpCartReturnData
  openwishlistLoadData: any;
  openwishlistReturnData: any;
  openwishlistTotal: any;
  siteInfo;
  isLogin = true;
  userTypes;
  category: any;
  spinner: any;
  reg_type: any;
  incrementDecrement_Url;
  fullreturn_message: string;
  totalPrice: any;
  subTotal: any = 0;
  loginOrNot: any;
  addcart_Url;
  isDisable: boolean = true;
  buttonName: String = "Edit"
  address: String;
  wishlist_Url;
  showAddtoCartReturnData: any;
  showWishlistReturnData: any;
  getData:any;
  gstPrice: any = 0;
  dis_price: any = 0;
  totalAmount: any = 0;
  identifier_Key: any;
  pass;
  confirmPass;
  changePassword_Url;
  public loading = false;
  oldpass;
  total_dis_price;
  gstAmmount;
  showTxt = "Show More";
  last_index = 100;
  counter = 100;
  firstCount = 100;
  getlogoLoadData;
  getlogo_Url;
  getlogoReturnData: any;
  expand: boolean = false;
  anchor: string = 'Read more';
  model: any = {};
  name: any;
  phone_no: any;
  returnreplaceOrder_Url;
  edit_Url;
  add_Url;
  getloading = false;
  accountList: any;
  returnBankData: any;
  // add_account = false;
  refund_Url;
  OnlyNumber: boolean;
  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private http: HttpClient,
    private commonUtils: CommonUtils, // common functionlity come here
    private loadingController: LoadingController,
    private authService: AuthService,
    private headerComponent: CommonHeaderComponent,
    private router: Router,
    private storage: Storage,
    private headerts: CommonHeaderComponent,
    private alertController: AlertController,
  ) { }

  ngOnInit() {
    // console.log('headerComponent..............',this.headerComponent);
    // this.userdata();
    this.getlogo();
    this.get_identifier = this.navParams.get('identifier');
    this.get_item = this.navParams.get('modalForm_item');
    this.get_array = this.navParams.get('modalForm_array');

    console.log('get_identifier', this.get_identifier);
    console.log('get_item', this.get_item);
    console.log('get_array', this.get_array);

    if (this.get_identifier == 'login_modal') {
      this.heder_title = 'login_modal ';
    } else if (this.get_identifier == 'register_Modal') {
      this.heder_title = 'register_Modal ';
      this.api_url = 'user/signup';
    } else if (this.get_identifier == 'cart_modal') {
      this.heder_title = 'cart_modal';
      // this.api_url = 'user/tempcart/return_index';
    } else if (this.get_identifier == 'wishlist_modal') {
      this.heder_title = 'wishlist_modal';
      this.api_url = 'user/wishlist/wishlist_return_index';
    }else if (this.get_identifier == 'profileInformation_Modal') {
      this.heder_title = 'profileInformation_Modal';
      this.userProfileData('user/userdata');
    } else if (this.get_identifier == 'address_Modal') {
      this.userProfileData('user/userdata');
      this.heder_title = 'address_Modal';
    }else if (this.get_identifier == 'cancelOrder_modal') {
      this.heder_title = 'Why do you want to cancel?';
    }else if (this.get_identifier == 'returnReplace_modal') {
      this.heder_title = 'Why do you want to Return Or Replacement Order?';
    }else if (this.get_identifier == 'addAccount_modal') {
      this.heder_title = 'New Bank Details';
      this.add_Url = 'user/user-bank-details';
      // this.getBankDetails(this.edit_Url);
    }else if (this.get_identifier == 'refundAmount_modal') {
      this.heder_title = 'Refund Account Details';
      this.edit_Url = 'user/get-user-bank-details';
      this.getBankDetails(this.edit_Url);
    }
    this.storage.get('setStroageGlobalParamsData').then(data => {
      this.loginOrNot = data;
      if (this.loginOrNot == null) {
        this.getCartTemp();
      } else {
        this.getCart();
        this.loginWishlist();
      }
    });
  }

  timeLeft: number = 60;
  interval;
  config = {
    allowNumbersOnly: true,
    length: 6,
  }
  startTimer() {
    console.log("Yes");
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.sendagain = false;
      } else {
        this.timeLeft = 0;
        this.registerReturnData = [];
        this.sendagain = true;
        this.pauseTimer();
      }
    }, 1000)
  }

  pauseTimer() {
    clearInterval(this.interval);
  }
  sendOtp() {
    this.loginbox = false;
    this.otpbox = true;
    this.startTimer();
  }

  otp;
  onOtpChange(otp) {
    this.otp = otp;
  }

  getlogo(){
    this.getlogoLoadData = true;
    this.getlogo_Url = `user/site-info`;
    this.http.get(this.getlogo_Url).subscribe(
      (res: any) => {
        this.getlogoReturnData = res.return_data;
        this.getlogoLoadData = false;
        console.log("Get Logo:<><><><><><><><> ", this.getlogoLoadData);
      },
      (err: any) => {
        this.getlogoLoadData = false;
      }
    );
  }

  closeModal() {
    console.log("No Call CloseModal");
    if(this.get_identifier == 'cart_modal'){
      this.modalController.dismiss('close-modal');
    }
    if(this.get_identifier == 'wishlist_modal'){
      this.modalController.dismiss('close-modal');
    }
    this.modalController.dismiss();
  }

  // userdata() { }

    // ..... register  modal start ......
    // async registerOpenModal(_identifier, _item, _items) {
    //   let changePassword_modal;
    //   changePassword_modal = await this.modalController.create({
    //     component: ModalPage,
    //     cssClass: 'mymodalClass small register_Modal',
    //     componentProps: { 
    //       identifier: _identifier,
    //       modalForm_item: _item,
    //       modalForm_array: _items
    //     }
    //   });
      
      // modal data back to Component
  //     changePassword_modal.onDidDismiss()
  //     .then((getdata) => {
  //       console.log('getdata >>>>>>>>>>>', getdata);
  //       if(getdata.data == 'submitClose'){

  //       }
  //     });
  //     return await changePassword_modal.present();
  // }
  // register modal end 

  onSubmitSingInForm(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    const password = form.value.password;
    let fd = new FormData();

    for (let val in form.value) {
      if (form.value[val] == undefined) {
        form.value[val] = '';
      }
      fd.append(val, form.value[val]);
    };

    this.authenticate(form, fd);
  }

  // authenticate function
  authenticate(_form, form_data) {
    this.isLoading = true;
    this.loadingController
      .create({ keyboardClose: true, message: 'Logging in...' })
      .then(loadingEl => {
        loadingEl.present();
        let authObs: Observable<any>;
        authObs = this.authService.login('user/user-login', form_data, '')
        this.formSubmitSubscribe = authObs.subscribe(
          resData => {
            console.log('resData ============= (sign in) ))))))))))))))>', resData);
            if (resData.return_status > 0) {
              console.log('user Type =============))))))))))))))>', resData.return_data.user_type);

              // this.router.navigateByUrl('/dashboard');
              // service 

              this.commonUtils.onClicksigninCheck(resData.return_data.user_info);

              // user details set
              this.commonUtils.onSigninStudentInfo(resData.return_data.user_info);

              setTimeout(() => {
                _form.reset();
                loadingEl.dismiss();
                this.modalController.dismiss('submitClose');
                this.commonUtils.presentToast('success', resData.return_message);
              }, 2000);
              this.addCartFromTmpCart();
              
            } else {
              loadingEl.dismiss();
              this.commonUtils.presentToast('error', resData.return_message);
            }

            this.isLoading = false;
          },
          errRes => {
            loadingEl.dismiss();
          }
        );
      });
  }
  // login form submit end



  onSubmitnumberRegisterForm(form: NgForm, get_identifier2) {

    this.registerFormSubscribe = this.http.post(this.api_url, form.value).subscribe(
      (res: any) => {
        this.name = form.value.name;
        this.phone_no = form.value.phone_no;
        this.isLoading = true;
        if (res.return_status == 1) {
          this.registerReturnData = res.return_data;
          this.registerReturnOTP = res.return_otp;
          this.commonUtils.presentToast('success', res.return_message);
          this.loginbox = false;
          this.otpbox = true;
          this.startTimer();
          if (get_identifier2 == 'otp_Modal') {
            this.heder_title = 'otp_Modal';
            this.api_url = 'user/otpvalidation';
          }
        }
        else {
          this.loginbox = true;
          this.otpbox = false;
          this.commonUtils.presentToast('error', res.return_message);
          return false;
        }
      },
      errRes => {
        this.commonUtils.presentToast('error', errRes.return_message);
      }
    );
    
  }
  //Mobile register submit End

  onsubmitChangePassword(form: NgForm){
    this.changePassword_Url = 'user/change-password';
    this.oldpass = form.value.old_password;
    this.pass = form.value.new_password;
    this.confirmPass = form.value.confirm_Password;
    var fd = new FormData()
    {
      fd.append('oldpassword',form.value.old_password);
      fd.append('newpassword',form.value.new_password);
    }
    if(this.pass == this.confirmPass){
      this.changePasswordFormSubscribe = this.http.post(this.changePassword_Url, fd).subscribe(
        (res: any) => {
          this.isLoading = true;
          this.closeModal();
          this.commonUtils.presentToast('success', res.return_message);
        },
        errRes => {
          this.commonUtils.presentToast('error', errRes.return_message);
        }
      );
    }else{
      this.commonUtils.presentToast('error', 'New Password and Confirm Password Are does not match');
      return false;
    }
  }

  //otp validation Start

  onSubmitOtpValidationForm(form: NgForm, get_identifier1) {
    var fd = new FormData()
    {
      fd.append('name', this.name);
      fd.append('phone_no', this.phone_no);
      fd.append('otp', this.otp);
      fd.append('otpcheck', this.registerReturnData);
    }
    this.otpValidationFormSubscribe = this.http.post(this.api_url, fd).subscribe(
      (res: any) => {
        this.isLoading = true;
        if (res.return_status == 1) {
          this.commonUtils.presentToast('success', res.return_message);
          this.otpbox = false;
          this.passwordbox = true;
          if (get_identifier1 == 'createpassword_Modal') {
            this.heder_title = 'createpassword_Modal';
            this.api_url = 'user/setpassword';
          }
        }
        else {
          this.passwordbox = false;
          this.otpbox = true;
          this.commonUtils.presentToast('error', res.return_message);
        }
      },
      errRes => {
        this.commonUtils.presentToast('error', errRes.return_message);
      }
    );
    
  }

  //----------otp validation End-----------------

  // ------------Create password Start-----------

  oncreatePasswordValidationForm(form: NgForm) {
    var fd = new FormData()
    {
      fd.append('phone_no', this.phone_no);
      fd.append('password', form.value.password);
    }
    this.createPasswordFormSubscribe = this.http.post(this.api_url, fd).subscribe(
      (res: any) => {
        this.isLoading = true;
        this.closeModal();
        this.commonUtils.presentToast('success', res.return_message);
      },
      errRes => {
        this.commonUtils.presentToast('error', errRes.return_message);
      }
    );
  }

  // ------------Create password End-----------

  // ------------openCartItems When Uesr not login-------------- 
  getCartTemp() {
    this.isLoading = true;
    this.opencartLoadData = true;
    this.opencartDataSubscribe = this.http.get('user/tempcart/return_index').subscribe(
      (res: any) => {
        this.opencartLoadData = false;
        this.getData = res.return_data.data;
        this.gstPrice = 0;
        this.subTotal = 0;
        this.dis_price = 0;
        this.totalAmount = 0;
        for (let i = 0; i < this.getData.length; i++) {
          this.getData[i].totalprice = this.getData[i].product_detail.discountPrice * this.getData[i].quantity;
          this.subTotal = this.subTotal + this.getData[i].totalprice;
          this.total_dis_price = Math.round((this.subTotal * this.getData[i].product_detail.discount) / 100);
          this.gstAmmount = Math.round((this.subTotal * this.getData[i].product_detail.gstamount) / 100);
          this.totalAmount = Math.round((this.subTotal + this.gstAmmount) - this.total_dis_price);
        }
        for (let i = 0; i < this.getData.length; i++) {
          this.last_index = (this.getData[i].product_get.product_desc.substring(0, 100)).lastIndexOf(' ');
          if(this.last_index > 100){
            this.last_index = 100;
            this.counter = this.last_index;
          }
        }
        this.opencartReturnData = this.getData;
        this.opentotaldata = res.return_data.total;
      },
      errRes => {
        this.opencartLoadData = false;
      }
    );
  }
  // ---------------------- openCartItems When Uesr not login -----------------

//  --------------Show More Start----------
toggleSkil(_id){
  console.log("Not Working Show more and show less.");
  if(this.counter < 101 ){
      console.log("Not Working Show more and show less Under5555.");
      for (let i = 0; i < this.getData.length; i++) {
        this.counter = this.getData[i].product_get.product_desc.length;
        this.showTxt = "Show less";
      }
  }
  else {
    this.counter = this.last_index;
    this.showTxt = "Show More"
  }
}

// toggle(index, _id) {
//   if(this.getData[index].id == _id){
//     this.expand = !this.expand;
//     this.anchor = this.expand ? 'Show less' : 'Read more';
//   }
// }
  //  --------------Show More End----------

  // ----------------------openCartItems When Uesr login ---------------------

  getCart() {
    this.opencartLoadData = true;
    this.loading = true;
    this.opencartDataSubscribe = this.http.get('user/cart/cart_return_index').subscribe(
      (res: any) => {
        this.loading = false;
        this.identifier_Key = res.key;
        this.opencartLoadData = false;
        this.getData = res.return_data.data;
        this.gstPrice = 0;
        this.subTotal = 0;
        this.dis_price = 0;
        this.totalAmount = 0;
        for (let i = 0; i < this.getData.length; i++) {
          this.getData[i].totalprice = this.getData[i].product_detail.discountPrice * this.getData[i].quantity;
          this.subTotal = this.subTotal + this.getData[i].totalprice;
          this.total_dis_price = Math.round((this.subTotal * this.getData[i].product_detail.discount) / 100);
          this.gstAmmount = Math.round((this.subTotal * this.getData[i].product_detail.gstamount) / 100);
          this.totalAmount = Math.round((this.subTotal + this.gstAmmount) - this.total_dis_price);
        }
        this.opencartReturnData = this.getData;
        for (let i = 0; i < this.getData.length; i++) {
          this.last_index = (this.getData[i].product_get.product_desc.substring(0, 100)).lastIndexOf(' ');
          if(this.last_index > 100){
            this.last_index = 100;
            this.counter = this.last_index;
          }
        }
        this.opentotaldata = res.return_data.total;
      },
      errRes => {
        this.loading = false;
        this.opencartLoadData = false;
      }
    );
  }

  // ----------------------openCartItems When Uesr login ---------------------

  incrementDecrement(index: any, process: any, id: any) {
    this.storage.get('setStroageGlobalParamsData').then(data => {
      this.loginOrNot = data;
      if (this.loginOrNot == null) {
        this.tempIncrementDecrement(index, process, id);
      } else {
        this.logincrementDecrement(index, process, id);
      }
    });
  }

  // -------------Login increment Decrement-----------

  tempIncrementDecrement(index: any, process: any, id: any) {
    this.incrementDecrement_Url = 'user/tempcart/add_remove_tmpcart';
    if (process == 'increment') {
      if (this.opencartReturnData[index].quantity < this.opencartReturnData[index].product_detail.stock_qty) {
        if (this.opencartReturnData[index].quantity < 10) {
          this.opencartReturnData[index].quantity = this.opencartReturnData[index].quantity + 1;
          this.opencartReturnData[index].totalprice = this.opencartReturnData[index].quantity * this.opencartReturnData[index].product_detail.discountPrice;
          this.subTotal = this.subTotal + this.opencartReturnData[index].product_detail.discountPrice;
          this.gstAmmount = Math.round((this.subTotal * this.opencartReturnData[index].product_detail.gstamount) / 100);
          this.total_dis_price = Math.round((this.subTotal * this.opencartReturnData[index].product_detail.discount) / 100);
          this.totalAmount = (this.subTotal  + this.gstAmmount ) - this.total_dis_price;
          let fd = new FormData();
          fd.append('id', id)
          fd.append('type', 'update_qnty')
          fd.append('quantity', this.opencartReturnData[index].quantity)
          this.incrementDecrementDataSubscribe = this.http.post(this.incrementDecrement_Url, fd).subscribe(
            (res: any) => {
              if (res.return_status > 0) {
                this.commonUtils.presentToast('success', res.return_message);
              }
            },
            errRes => {
            }
          );
        } else {
          this.commonUtils.presentToast('warning', "We're sorry! Only 10 unit(s) allowed in each order");
        }

      }

      else {
        this.commonUtils.presentToast('warning', 'Only ' + this.opencartReturnData[index].product_detail.stock_qty + ' unit avaliable now');
      }
    } else if (process == 'decrement') {
      if (this.opencartReturnData[index].quantity > 1) {
        this.opencartReturnData[index].quantity = this.opencartReturnData[index].quantity - 1;
        this.opencartReturnData[index].totalprice = this.opencartReturnData[index].quantity * this.opencartReturnData[index].product_detail.discountPrice;
          this.subTotal = this.subTotal - this.opencartReturnData[index].product_detail.discountPrice;
          this.gstAmmount = Math.round((this.subTotal * this.opencartReturnData[index].product_detail.gstamount) / 100);
          this.total_dis_price = Math.round((this.subTotal * this.opencartReturnData[index].product_detail.discount) / 100);
          this.totalAmount = (this.subTotal  + this.gstAmmount ) - this.total_dis_price;
        let fd = new FormData();
        fd.append('id', id)
        fd.append('type', 'update_qnty')
        fd.append('quantity', this.opencartReturnData[index].quantity)
        this.incrementDecrementDataSubscribe = this.http.post(this.incrementDecrement_Url, fd).subscribe(
          (res: any) => {
            if (res.return_status > 0) {
              this.commonUtils.presentToast('success', res.return_message);
            }
          },
          errRes => {
          }

        );
      }
      else {
      }
    }
  }

  // --------------Logout Increment Decrement-------------
  logincrementDecrement(index: any, process: any, id: any) {
    this.incrementDecrement_Url = 'user/cart/add_remove';
    if (process == 'increment') {
      if (this.opencartReturnData[index].quantity < this.opencartReturnData[index].product_detail.stock_qty) {
        if (this.opencartReturnData[index].quantity < 10) {
          this.opencartReturnData[index].quantity = this.opencartReturnData[index].quantity + 1;
          this.opencartReturnData[index].totalprice = this.opencartReturnData[index].quantity * this.opencartReturnData[index].product_detail.discountPrice;
          this.subTotal = this.subTotal + this.opencartReturnData[index].product_detail.discountPrice;
          this.gstAmmount = Math.round((this.subTotal * this.opencartReturnData[index].product_detail.gstamount) / 100);
          this.total_dis_price = Math.round((this.subTotal * this.opencartReturnData[index].product_detail.discount) / 100);
          this.totalAmount = (this.subTotal  + this.gstAmmount ) - this.total_dis_price;
          let fd = new FormData();
          fd.append('id', id)
          fd.append('type', 'update_qnty')
          fd.append('quantity', this.opencartReturnData[index].quantity)
          this.incrementDecrementDataSubscribe = this.http.post(this.incrementDecrement_Url, fd).subscribe(
            (res: any) => {
              if (res.return_status > 0) {
                this.commonUtils.presentToast('success', res.return_message);
              }
            },
            errRes => {
            }
          );
        } else {
          this.commonUtils.presentToast('warning', "We're sorry! Only 10 unit(s) allowed in each order");
        }
      }
      else {
        this.commonUtils.presentToast('warning', 'Only ' + this.opencartReturnData[index].product_detail.stock_qty + ' unit avaliable now');
      }
    }else if (process == 'decrement') {
      if (this.opencartReturnData[index].quantity > 1) {
        this.opencartReturnData[index].quantity = this.opencartReturnData[index].quantity - 1;
        this.opencartReturnData[index].totalprice = this.opencartReturnData[index].quantity * this.opencartReturnData[index].product_detail.discountPrice;
        this.subTotal = this.subTotal - this.opencartReturnData[index].product_detail.discountPrice;
        this.gstAmmount = Math.round((this.subTotal * this.opencartReturnData[index].product_detail.gstamount) / 100);
        this.total_dis_price = Math.round((this.subTotal * this.opencartReturnData[index].product_detail.discount) / 100);
        this.totalAmount = (this.subTotal  + this.gstAmmount ) - this.total_dis_price;
        let fd = new FormData();
        fd.append('id', id)
        fd.append('type', 'update_qnty')
        fd.append('quantity', this.opencartReturnData[index].quantity)
        this.incrementDecrementDataSubscribe = this.http.post(this.incrementDecrement_Url, fd).subscribe(
          (res: any) => {
            if (res.return_status > 0) {
              this.commonUtils.presentToast('success', res.return_message);
            }
          },
          errRes => {
          }

        );
      }
      else {
      }
    }
  }
  // -------------openCartItems End-----------------

  //--------------Add Remove Card------------------

  cartremoveItem(id) {
    this.storage.get('setStroageGlobalParamsData').then(data => {
      this.loginOrNot = data;
      if (this.loginOrNot == null) {
        this.tempRemoveItem(id);
      } else {
        this.login_RemoveItem(id);
      }
    });
  }

  async tempRemoveItem(id: any) {
    const alert = await this.alertController.create({
      header: 'Delete...!',
      message: "<img class='cart_remove_img' src='assets/images/trash-can.gif'><div class='cart_remove'>Do you really want to delete this item ?</div>",
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'popup-cancel-btn',
          handler: (blah) => {
          }
        }, {
          text: 'Ok',
          cssClass: 'popup-ok-btn',
          handler: () => {
            this.addcart_Url = `user/tempcart/add_remove_tmpcart`;
            let fd = new FormData();
            fd.append('id', id)
            fd.append('type', 'delete')
            this.addTocartDataSubscribe = this.http.post(this.addcart_Url, fd).subscribe(
              (res: any) => {
                if (res.return_status > 0) {
                  this.commonUtils.presentToast('success', res.return_message);
                  this.getCartTemp();
                  this.headerts.headerShowCartCount();
                }
              },
              errRes => {
              }
            );
          }
        }
      ]
    });
    await alert.present();
  }
 
  async login_RemoveItem(id:any) {
    const alert = await this.alertController.create({
      header: 'Delete...!',
      message: "<img class='cart_remove_img' src='assets/images/trash-can.gif'><div class='cart_remove'>Do you really want to delete this item ?</div>",
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'popup-cancel-btn',
          handler: (blah) => {
          }
        }, {
          text: 'Ok',
          cssClass: 'popup-ok-btn',
          handler: () => {
            this.addcart_Url = `user/cart/add_remove`;
            let fd = new FormData();
            fd.append('id', id)
            fd.append('type', 'delete')
            this.addTocartDataSubscribe = this.http.post(this.addcart_Url, fd).subscribe(
              (res: any) => {
                if (res.return_status > 0) {
                  this.commonUtils.presentToast('success', res.return_message);
                  this.getCart();
                  this.headerts.headerShowCartCount();
                }
              },
              errRes => {
              }
            );
          }
        }
      ]
    });
    await alert.present();
  }

  //----------Add Remove Card-------------

  //-----------Add Cart Form Tepurary Cart------------
  addCartFromTmpCart() {
    this.addCartFromTmpCartLoadData = true;
    this.addCartFromTmpCartDataSubscribe = this.http.get('user/login/get_tmpcart').subscribe(
      (res: any) => {
        console.log('res temp cart from modal', res);
        this.addCartFromTmpCartLoadData = false;
        this.addCartFromTmpCartReturnData = res.return_data.data;
        window.location.reload();
      },
      errRes => {
        this.addCartFromTmpCartLoadData = false;
      }
    );
  }
  //-----------Add Cart Form Tepurary Cart---------

  //----------------Open Wishlist Item when user login Start-----------

  loginWishlist(){
    this.openwishlistLoadData = true;
    this.openwishlistDataSubscribe = this.http.get('user/wishlist/wishlist_return_index').subscribe(
      (res: any) => {
        console.log('openwishlistData ResData.....', res);
        this.openwishlistLoadData = false;
        this.openwishlistReturnData = res.return_data.data;
        this.openwishlistTotal = res.return_data.total;
      },
      errRes => {
        this.opencartLoadData = false;
      }
    );
  }

  getWishList(_identifier, _item, _items) {
    this.storage.get('setStroageGlobalParamsData').then(async data => {
      this.loginOrNot = data;
      if (this.loginOrNot == null) {
        let changePassword_modal;
        changePassword_modal = await this.modalController.create({
          component: ModalPage,
          cssClass: 'mymodalClass small login_Modal',
          componentProps: { 
            identifier: _identifier,
            modalForm_item: _item,
            modalForm_array: _items
          }
        });
    
    // modal data back to Component
    changePassword_modal.onDidDismiss()
    .then((getdata) => {
      if(getdata.data == 'submitClose'){
        /* this.onListData(this.listing_url, this.displayRecord, this.pageNo, this.api_parms, this.searchTerm, this.cherecterSearchTerm, this.sortColumnName, this.sortOrderName, this.advanceSearchParms, this.urlIdentifire);  */
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.onSameUrlNavigation = 'reload';
        // console.log("After login from shantanu");
        // window.location.reload();
      }
    });
    return await changePassword_modal.present();
      } else {
        this.loginWishlist();
      }
    });
  }

  //---------------Open Wishlist Item when user login End-----------------

  //--------------remove wishlist item when user login Start------------------
  wishlistremoveItem(id) {
    this.storage.get('setStroageGlobalParamsData').then(data => {
      this.loginOrNot = data;
      if (this.loginOrNot == null) {
        console.log("You are not login");

      } else {
        console.log("You are login");
        this.login_wishlistItem(id);
      }
    });
  }

  
  async login_wishlistItem(id: any) {
    const alert = await this.alertController.create({
      header: 'Delete...!',
      message: "<img class='wish_remove_img' src='assets/images/trash-can.gif'><div class='wish_remove'>Do you really want to delete selected item ?</div>",
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'popup-cancel-btn',
          handler: (blah) => {
          }
        }, {
          text: 'Ok',
          cssClass: 'popup-ok-btn',
          handler: () => {
            this.wishlist_Url = `user/wishlist/return_add`;
            console.log('wishlist Id.........', id);
            let fd = new FormData();
            fd.append('id', id)
            fd.append('type', 'true')
            this.wishlistDataSubscribe = this.http.post(this.wishlist_Url, fd).subscribe(
              (res: any) => {
                if (res.return_status > 0) {
                  this.commonUtils.presentToast('success', res.return_message);
                  this.ngOnInit()
                  this.headerts.headerShowCartCount();
                }
              },
              errRes => {
              }
            );
          }
        }
      ]
    });
    await alert.present();

  }
  //---------------remove wishlist item when user login End----------------

  //---------user profile data---------------
  private userProfileData(url): void {
    this.http.get(url).subscribe(
      (res: any) => {
        this.name = res.return_data.name;
        this.phone_no = res.return_data.phone_no;
        this.address = res.return_data.address.address;
      },
      errRes => {
      }
    );
  }

  //user profile data

  //profile Edit Data
  editData() {
    this.buttonName = "Submit"
    this.isDisable = false;
  }
  //profile Edit Data

  checkoutNow(_identifier){
    this.storage.get('setStroageGlobalParamsData').then(async data => {
      this.loginOrNot = data;
      if (this.loginOrNot == null) {
        const alert = await this.alertController.create({
          header: 'Login ....!',
          message: "<img class='check_login' src='assets/images/warning.gif'><div class='check_error'>You Are Not Login...</div><div class='check_error2'>**Please Login ☞</div>",
          buttons: [
            {
              text: 'Login',
              role: 'Ok',
              cssClass: 'popup-cancel-btn not-login-btn',
              handler: async (blah) => {
                let changePassword_modal;
                changePassword_modal = await this.modalController.create({
                  component: ModalPage,
                  cssClass: 'mymodalClass small login_Modal bg-gray-100',
                  componentProps: { 
                    identifier: 'login_modal',
                    modalForm_item: '',
                    modalForm_array: ''
                  }
                });
              
              // modal data back to Component
              changePassword_modal.onDidDismiss()
              .then((getdata) => {
                if(getdata.data == 'submitClose'){
                }
              });
              return await changePassword_modal.present();
                
              }
            },
          ]
        });
        await alert.present();
      } else {
        this.router.navigateByUrl(`checkout`+'/'+_identifier+'/'+'1');
        this.closeModal();
      }
    });
  }
  cancleOrder_Url;

  onSubmitCancelOrderForm(form: NgForm){
    this.cancleOrder_Url = 'user/order/cancel-user';
    this.isLoading = true;
    var fd = new FormData()
    {
      fd.append('id',this.get_item);
      fd.append('remarks',form.value.remarks);
    }
    this.canclOrderFormSubscribe = this.http.post(this.cancleOrder_Url, fd).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.modalController.dismiss("order-canceled");
        this.commonUtils.presentToast('success', res.return_message);
      },
      errRes => {
        this.isLoading = false;
        this.commonUtils.presentToast('error', errRes.return_message);
      }
    );
  }
 
  onSubmitReturnReplaceOrderForm(form: NgForm){
    this.returnreplaceOrder_Url = 'user/order/return-replace';
    this.isLoading = true;
    var fd = new FormData()
    {
      fd.append('id',this.get_item);
      fd.append('replace_return',form.value.replace_return);
      fd.append('remarks',form.value.remarks);
    }
    this.returnReplaceOrderFormSubscribe = this.http.post(this.returnreplaceOrder_Url, fd).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.modalController.dismiss("return-replace");
        this.commonUtils.presentToast('success', res.return_message);
      },
      errRes => {
        this.isLoading = false;
        this.commonUtils.presentToast('error', errRes.return_message);
      }
    );
  }
  getBankDetails(url){
    this.getloading = true;
    this.http.get(url).subscribe(
      (res: any) => {
        console.log("this.bankdetails..",res);
        this.getloading = false;
        this.returnBankData = res.return_data;
        for (let i = 0; i < this.returnBankData.length; i++) {
         const string = this.returnBankData[i].bank_account_no;
         const array = Object.assign([], string);
         for (let j = 0; j < array.length-3; j++) {
          array[j] = '*';
         }
         this.accountList = array.join("");
         this.returnBankData[i].bank_account_no = this.accountList;
        }
      },
      errRes => {
        this.getloading = false;
      }
    );
  }
  async addAccount(_identifier, _item, _items){
    let changePassword_modal;
    changePassword_modal = await this.modalController.create({
      component: ModalPage,
      cssClass: 'mymodalClass small register_Modal',
      componentProps: { 
        identifier: _identifier,
        modalForm_item: _item,
        modalForm_array: _items
      }
    });
    
    // modal data back to Component
    changePassword_modal.onDidDismiss()
    .then((getdata) => {
      if(getdata.data == 'addBank-account'){
        this.getBankDetails(this.edit_Url);
      }
    });
    return await changePassword_modal.present();
  }
  onSubmitBankAccountForm(form: NgForm){
    this.isLoading = true;
    this.refundFormSubscribe = this.http.post(this.add_Url, form.value).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.modalController.dismiss("addBank-account");
        this.commonUtils.presentToast('success', res.return_message);
      },
      errRes => {
        this.isLoading = false;
        this.commonUtils.presentToast('error', errRes.return_message);
      }
    );
  }
  onSubmitRefundForm(form: NgForm){
    console.log("onSubmitRefundForm Data...",form.value);
    this.refund_Url = 'user/user-current-account-add/'+form.value.account;
    var fd = new FormData()
    {
      fd.append('id',this.get_item);
    }
    this.isLoading = true;
    this.refundFormSubscribe = this.http.post(this.refund_Url, fd).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.modalController.dismiss("refund-amount");
        this.commonUtils.presentToast('success', res.return_message);
      },
      errRes => {
        this.isLoading = false;
        this.commonUtils.presentToast('error', errRes.return_message);
      }
    );
  }
  // destroy call
  ngOnDestroy() {
    if (this.formSubmitSubscribe !== undefined) {
      this.formSubmitSubscribe.unsubscribe();
    }
    if (this.registerFormSubscribe !== undefined) {
      this.registerFormSubscribe.unsubscribe();
    }
    if (this.otpValidationFormSubscribe !== undefined) {
      this.otpValidationFormSubscribe.unsubscribe();
    }
    if (this.createPasswordFormSubscribe !== undefined) {
      this.createPasswordFormSubscribe.unsubscribe();
    }
    if (this.opencartDataSubscribe !== undefined) {
      this.opencartDataSubscribe.unsubscribe();
    }
    if (this.incrementDecrementDataSubscribe !== undefined) {
      this.incrementDecrementDataSubscribe.unsubscribe();
    }
    if (this.addTocartDataSubscribe !== undefined) {
      this.addTocartDataSubscribe.unsubscribe();
    }
    if (this.addCartFromTmpCartDataSubscribe !== undefined) {
      this.addCartFromTmpCartDataSubscribe.unsubscribe();
    }
    if (this.openwishlistDataSubscribe !== undefined) {
      this.openwishlistDataSubscribe.unsubscribe();
    }
    if (this.changePasswordFormSubscribe !== undefined) {
      this.changePasswordFormSubscribe.unsubscribe();
    }
    if (this.canclOrderFormSubscribe !== undefined) {
      this.canclOrderFormSubscribe.unsubscribe();
    }
    if (this.returnReplaceOrderFormSubscribe !== undefined) {
      this.returnReplaceOrderFormSubscribe.unsubscribe();
    }
    if (this.refundFormSubscribe !== undefined) {
      this.refundFormSubscribe.unsubscribe();
    }
    
  }
  // destroy call

}
